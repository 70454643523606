@keyframes slide-down {
  0% {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    max-height: 500px; /* Adjust max-height based on your content */
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    max-height: 500px;
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
  }
}

.slide-down {
  animation: slide-down 0.3s ease-out forwards;
}

.slide-up {
  animation: slide-up 0.3s ease-out forwards;
}
